import React, { useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';

import {
    ThemeContext,
} from '@saladbob/sassafras'

import { Theme } from '../../types';

type CustomProps = React.CSSProperties & {
    theme: Theme,
    btnColor?: string,
    navBgColor?: string,
    size?: string,
};

type RuleNames = 'Button' | 'Active' | 'Nav';

type SizingMap = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;

type Props = {
    children: React.ReactNode,
    btnColor?: string,
    navBgColor?: string,
    size?: SizingMap,
};

const sizingMap = {
    xs: 'calc(1.25rem + 2px)',
    sm: 'calc(2.5rem + 2px)',
    md: 'calc(3rem + 2px)',
    lg: 'calc(3.5rem + 2px)',
    xl: 'calc(4rem + 2px)',
    xxl: 'calc(5rem + 2px)',
};

const getClasses = createUseStyles<RuleNames, CustomProps>({
    Button: {
        display: 'block',
        height: ({ size }: { size?: string }) => sizingMap[size],
        width: ({ size }: { size?: string }) => sizingMap[size],
        background: ({ theme, btnColor }: { theme: Theme, btnColor?: string }) => theme.colors[btnColor] || btnColor || 'none',
        position: 'relative',
        transform: 'rotate(90deg)',
        transition: 'all .35s ease',
        borderRadius: ({ size }: { size?: string }) => sizingMap[size],
        border: 'none',
        marginLeft: 'auto',
        marginRight: 0,
        '& span': {
            background: '#fff',
            height: '10%',
            width: '10%',
            borderRadius: '1rem',
            position: 'absolute',
            top: '25%',
            left: '45%',
            transition: 'all .35s ease',
            cursor: 'pointer',
        },
        '& span:nth-child(1)': {

        },
        '& span:nth-child(2)': {
            top: '45%',
        },
        '& span:nth-child(3)': {
            top: '65%',
        },
    },
    Active: {
        transform: 'rotate(0)',
        zIndex: '10002',
        '& span': {
            height: '6%',
            width: '60%',
            left: '20%',
        },
        '& span:nth-of-type(1)': {
            top: '50%',
            transform: 'rotate(45deg)',
        },
        '& span:nth-of-type(2)': {
            opacity: 0,
        },
        '& span:nth-of-type(3)': {
            top: '50%',
            transform: 'rotate(-45deg)',
        },
    },
    Nav: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '10000',
        left: 0,
        top: 0,
        background: ({ theme, navBgColor }: { theme: Theme, navBgColor?: string }) => theme.colors[navBgColor] || navBgColor || 'none',
    },
});

const MobileNav: React.FC<Props> = ({
    children, btnColor, navBgColor, size,
}) => {
    const [active, setActive] = useState(false);
    const theme = useContext(ThemeContext);

    const classes = getClasses({
        theme,
        btnColor,
        navBgColor,
        size,
    });

    const toggle = () => {
        setActive(!active);
    };

    return (
        <>
            <button className={`${classes.Button} ${active ? classes.Active : ''}`} type="button" aria-label="Toggle Navigation" onClick={toggle}>
                <span />
                <span />
                <span />
            </button>
            {active && (
                <div className={classes.Nav} onClick={toggle} role="button">
                    <div>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

MobileNav.defaultProps = {
    size: 'md',
    navBgColor: 'primary800',
    btnColor: 'primaryA700',
};

export default MobileNav;
