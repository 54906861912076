export const search = `query getTeamsByName($name: String!) {
    Teams (where: { name: { contains: $name } }) {
      docs {
        id
        name
        color
        color2
        players {
            id
            fname
            lname
            gender
            height
            experience
            dob
            tshirt
        }
      }
    }
}`;

export const staffTeams = `query getTeamsByName($name: String, $limit: Int, $sort: String, $page: Int) {
    Teams (where: { name: { contains: $name } }, limit: $limit, page: $page, sort: $sort) {
      docs {
        id
        name
        color
        color2
        players {
            id
            fname
            lname
            gender
            height
            experience
            dob
            tshirt
        }
      }
      totalDocs
      page
      totalPages
    }
}`;

export default `query getTeamById($id: String!) {
    Team (id: $id) {
      id
      name
      color
      color2
      ageDivision
      genderDivision
      wins
      losses
      trophies {
          title
          place
      }
      logo {
          id
          url
          color
          sizes {
          blur {
              url
          }
          thumbnail {
              url
          }
        }
      }
      canEdit
      manager {
        value {
            ... on User {
                id
                fname
                lname
                email
                phone
                address1
                address2
                city
                state
                postcode
                country
            }
        }
      }
      players {
          id
          fname
          lname
          address1
          address2
          city
          state
          postcode
          phone
          state
          country
          email
          gender
          height
          experience
          canEdit
          dob
          tshirt
          experience
          verified
          verifiedAt
      }
    }
  }`;