export default (id) => ({
  query: `query {
    Tournaments (where: { organizers: { in: "${id}" } }) {
    	docs {
        id
        title
        start
        end
        slug
        status
        host {
          slug
        }
        teams {
          id
          name
        }
        players {
          dob
          height
          experience
          gender
          city
          state
          tshirt
        }
        image {
          url
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      totalDocs
      page
      totalPages
    }
    Files {
      docs {
        id
        filename
        filesize
        url
        name
        updatedAt
      }
    }
    Notices (where: { organizers: { in: "${id}" } }) {
      docs {
        id
        title
        brief
        content
        type
        expiration
        image {
          url
          name
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
          }
        }
        createdAt
      }
      totalDocs
      page
      totalPages
    }
  }`
});