import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import qs from 'qs';
import Dashboard from '../crud/gql/Dashboard';
import OrgDashboard from '../crud/gql/OrgDashboard';
import TeamQL from '../crud/gql/Team';
import Order from '../crud/gql/Order';

import type { TeamDepth } from '../types';


const fetcher = (url) => fetch(url, {
    credentials: 'include',
}).then((res) => res.json());

const graphqlFetch = ([url, data]) => fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
}).then((res) => res.json());

function fetchTeams (userID: string) {
    const { data, error, isLoading } = useSWR(userID ? `${process.env.NEXT_PUBLIC_API_URL}/team?where[manager.value][equals]=${userID}&depth=1` : null, fetcher);

    return {
        teams: data?.docs || [],
        isLoading: isLoading || !data,
        isError: error || data?.errors,
    }
}

function fetchTeamsNotIn(userID: string, tournamentID: string) {
    const { data, error, isLoading } = useSWR(userID ? `${process.env.NEXT_PUBLIC_API_URL}/team/notin/${tournamentID}` : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    return {
        teams: data?.docs || [],
        isLoading: isLoading || !data,
        isError: error || data?.errors,
    }
}

function fetchTeam(id: string): { team: TeamDepth, isLoading: boolean, isError: any[], mutate: Function } {
    const query = useMemo(() => {
        return {
            query: TeamQL,
            variables: {
                id: id,
            },
        }
    }, [id]);

    const { data, error, isLoading, mutate } = useSWR(id ? [`${process.env.NEXT_PUBLIC_API_URL}/graphql`, query] : null, graphqlFetch, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    return {
        team: data?.data?.Team || {},
        isLoading: isLoading || !data,
        isError: error || data?.errors,
        mutate,
    }
}

function fetchUser () {
    const { data, error, isLoading, mutate } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/user/me`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    return {
        user: data?.user,
        isLoading,
        isError: error || data?.errors,
        mutate,
    }
}

function fetchBench (userId: string) {
    const qstring = useMemo(() => 
        qs.stringify({
            where: {
                id: {
                    not_in: [userId],
                },
                or: [{
                    'createdBy.value': {
                        equals: userId,
                    },
                }, {
                    administer: {
                        equals: userId,
                    },
                }, {
                    friends: {
                        in: userId,
                    },
                }]
            },
            depth: 0,
            sort: 'lname',
        }), [userId]);

    const { data, error, isLoading } = useSWR(userId ? `${process.env.NEXT_PUBLIC_API_URL}/user?${qstring}` : null, fetcher, {
        revalidateIfStale: false,
    });

    return {
        users: data,
        isLoading,
        isError: error || data?.errors,
    }
}

function mutateUser (data) {
    mutate(`${process.env.NEXT_PUBLIC_API_URL}/user/me`, data);
}

function fetchAdmin () {
    const { data, error, isLoading, mutate } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/admins/me`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    return {
        admin: data?.user,
        isLoading,
        isError: error || data?.errors,
        mutate,
    }
}

function fetchDashboard (userId: string) {
    const qstring = qs.stringify({
        where: {
            or: [{
                players: {
                    in: [userId],
                },
            }, {
                'createdBy.value': {
                    equals: userId,
                },
            }, {
                'manager.value': {
                    equals: userId,
                },
            }],
        },
        depth: 1,
        limit: 5,
    });

    const { data: teams, mutate: mutateTeams } = useSWR(userId ? [`${process.env.NEXT_PUBLIC_API_URL}/team?${qstring}`] : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    const { data: record, } = useSWR(userId ? [`${process.env.NEXT_PUBLIC_API_URL}/user/record`] : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    const query = useMemo(() => {
        return {
            query: Dashboard,
            variables: {
                id: userId,
                teams: teams && teams.docs ? teams.docs.map((team) => team.id) : [],
                today: new Date(),
            },
        }
    }, [userId, teams]);

    const {data, error, isLoading } = useSWR(userId ? [`${process.env.NEXT_PUBLIC_API_URL}/graphql`, query] : null, graphqlFetch, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    return {
        teams: teams,
        mutateTeams,
        tournaments: data?.data?.Tournaments,
        trophies: data?.data?.Trophies,
        errors: error || data?.data?.errors,
        record: record,
        isLoading,
    }
}

function fetchOrgDashboard (userId: string) {
    const query = useMemo(() => OrgDashboard(userId), [userId]);
    const { data, error, isLoading } = useSWR([`${process.env.NEXT_PUBLIC_API_URL}/graphql`, query], graphqlFetch);
    return {
        tournaments: data?.data?.Tournaments,
        notices: data?.data?.Notices,
        files: data?.data?.Files,
        errors: error || data?.data?.errors,
        isLoading,
    }
}

function fetchUsers(query: any, tournamentID?: string) {
    const qstring = qs.stringify(query);
    const url = tournamentID ? `${process.env.NEXT_PUBLIC_API_URL}/user/bench/${tournamentID}?${qstring}` : `${process.env.NEXT_PUBLIC_API_URL}/user/bench?${qstring}`;
    const { data, error, isLoading } = useSWR(url, fetcher, {
        revalidateIfStale: false,
    });

    return {
        users: data?.docs,
        isLoading,
        isError: error || data?.errors,
    }
}

function fetchOrder(paymentIntentID: string) {
    const query = useMemo(() => {
        return {
            query: Order,
            variables: {
                piID: paymentIntentID,
            },
        }
    }, [paymentIntentID]);

    const { data, error, isLoading, mutate } = useSWR(
        paymentIntentID ? [`${process.env.NEXT_PUBLIC_API_URL}/graphql`, query] : null,
        graphqlFetch,
    );

    return {
        order: data?.data?.Orders?.docs[0],
        errors: error || data?.data?.errors,
        isLoading: paymentIntentID ? isLoading : true,
        mutate,
    }
}

function fetchUpcomingTournaments(args?: { sort?: string, page?: number, limit?: number }) {
    const { sort = 'title', page = 1, limit = 50 } = args || {};
    const query = useMemo(() => {
        return qs.stringify({
            where: '$gte',
            sort,
            page,
            limit,
        });
    }, [sort, page, limit]);
    const { data, error, isLoading } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/tournament/with-count?${query}`, fetcher);
    return {
        tournaments: data,
        page: data?.page,
        totalPages: data?.totalPages,
        totalDocs: data?.totalDocs,
        errors: error || data?.errors,
        isLoading,
    }
}

function fetchPastTournaments(args? : { sort?: string, page?: number, limit?: number }) {
    const { sort = 'title', page = 1, limit = 50 } = args || {};
    const query = useMemo(() => {
        return qs.stringify({
            where: '$lt',
            sort,
            page,
            limit,
        });
    }, [sort, page, limit]);

    const { data, error, isLoading } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/tournament/with-count?${query}`, fetcher);
    return {
        tournaments: data,
        page: data?.page,
        totalPages: data?.totalPages,
        totalDocs: data?.totalDocs,
        errors: error || data?.errors,
        isLoading,
    }
}

export {
    fetchUpcomingTournaments,
    fetchPastTournaments,
    fetchBench,
    fetchTeam,
    fetchTeams,
    fetchTeamsNotIn,
    fetchUser,
    fetchUsers,
    mutateUser,
    fetchDashboard,
    fetchOrgDashboard,
    fetchAdmin,
    fetchOrder,
}