import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import {
    Type,
    Box,
    Grid,
} from '@saladbob/sassafras'

import { MenuItem } from '../../types'

type GetLinkProps = {
    item: any,
    textColor?: string,
    font?: string,
    weight?: string,
    uppercase?: boolean,
};

type NavItemProps = {
    item: any,
    color?: string,
    font?: string,
    weight?: string,
    submenuBgColor?: string,
    uppercase?: boolean,
};

type Props = {
    nav: MenuItem[],
    color?: string,
    font?: string,
    weight?: string,
    vertical?: boolean,
    submenuBgColor?: string,
    uppercase?: boolean,
};

const getLink: React.FC<GetLinkProps> = ({ item, textColor, font, weight, uppercase = true }) => {
    if (item.link  && item.link.includes('http')) {
        return (
            <Type
                tag="a"
                href={item.link}
                color={textColor}
                font={item.font || font}
                target={item.newtab ? '_blank' : null}
                weight={item.weight || weight}
                decoration={false}
                uppercase={uppercase}
                nowrap
            >
                {item.label}
            </Type>
        );
    } else if (item.link) {
        return (
            <Link
                href={item.link}
            >
                <Type
                    tag="span"
                    color={textColor}
                    font={item.font || font}
                    target={item.newtab ? '_blank' : null}
                    weight={item.weight || weight}
                    decoration={false}
                    uppercase={uppercase}
                    nowrap
                >
                    {item.label}
                </Type>
            </Link>
        );
    } else if (item.page) {
        return (
            <Link
                href={`/${item.page.slug}`}
            >
                <Type
                    tag="span"
                    color={textColor}
                    font={item.font || font}
                    target="_blank"
                    weight={item.weight || weight}
                    decoration={false}
                    uppercase={uppercase}
                    nowrap
                >
                    {item.label}
                </Type>
            </Link>
        );
    }
    return null;
}

const NavItem: React.FC<NavItemProps> = ({ item, color, submenuBgColor, font, weight, uppercase }) => {
    const [hover, setHover] = useState(false);
    const textColor = hover && item.submenu?.length ? 'white' : item.color || color;
    const bgColor = hover && item.submenu?.length ? submenuBgColor || item.color || color : null;

    return (
        <Box
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            padding={['xs', 'sm']}
            bgColor={bgColor}
            height="100%"
            align="center"
            justify="center"
        >
            {getLink({
                item,
                textColor,
                font,
                weight,
                uppercase
            })}

            {item.submenu && item.submenu.length > 0 && (
                <Type
                    color={textColor}
                    font="small"
                    tag="span"
                    ms="xs">
                    ▾
                </Type>
            )}
            <AnimatePresence>
                {item.submenu && hover && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            zIndex: 100,
                        }}
                    >
                        <Box
                            display="block"
                            bgColor={submenuBgColor || item.color || color}
                            width="auto"
                        >
                            {item.submenu.map((subitem) => (
                                <Box key={subitem.id} padding="sm">
                                    {getLink({
                                        item: subitem,
                                        textColor: subitem.color || 'white',
                                        font: subitem.font || font,
                                        weight: subitem.weight || 'bold',
                                        uppercase,
                                    })}
                                </Box>
                            ))}
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    );
};

const Nav: React.FC<Props> = ({
    nav, font, color, weight, vertical, submenuBgColor, uppercase
}) => {
    return (
        <Grid
            width="auto"
            height="100%"
            align="center"
            gridGap="md"
            columns={vertical ? '1fr' : `repeat(${nav.length}, min-content)`}
        >
            {nav && nav.map((item) => (
                <NavItem key={item.id} submenuBgColor={submenuBgColor} item={item} color={color} font={font} weight={weight} uppercase={uppercase} />
            ))}
        </Grid>
    );
};

Nav.defaultProps = {
    font: 'p',
};

export default Nav;
