export default `query($id: JSON!, $today: DateTime!, $teams: [JSON!]) {
    Tournaments (where: { teams: { in: $teams }, start: { greater_than: $today } }) {
    	docs {
        id
        title
        start
        end
        slug
        host {
          slug
        }
        teams {
          id
          name
        }
        image {
          url
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      totalDocs
      page
      totalPages
    }
    PastTournaments: Tournaments (where: { teams: { in: $teams }, start: { less_than: $today } }) {
    	docs {
        id
        title
        start
        end
        slug
        host {
          slug
        }
        image {
          url
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      totalDocs
      page
      totalPages
    }
    Trophies (where: { winner: { in: [$id] } }) {
      docs {
        id
        title
        place
        court {
          title
        }
        tournament {
          id
          title
          start
          image {
            url
            color
            sizes {
              blur {
                url
              }
              thumbnail {
                url
              }
            }
          }
        }
        team {
          id
          name
          logo {
            url
            color
            sizes {
              blur {
                url
              }
              thumbnail {
                url
              }
            }
          }
        }
        image {
          url
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
            hd {
              url
            }
          }
        }
      }
    }
  }`;