export default `query getOrder($piID: String!) {
    Orders (where: { paymentIntentID: { equals: $piID }}) {
        docs {
            id
            orderNumber
            paymentIntentID
            amount
            items {
                team {
                    id
                    name
                    logo {
                        sizes {
                            thumbnail {
                                url
                            }
                        }
                    }
                    players {
                        id
                        fname
                        lname
                    }
                }
                tournament {
                    id
                    title
                    image {
                        sizes {
                            thumbnail {
                                url
                            }
                        }
                    }
                }
                price
                qty
            }
            paymentMethod {
                type
                brand
                last4
            }
        }
    }
  }`;